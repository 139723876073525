import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div className="contact" id="contact">
      <h3>04. What's next?</h3>
      <h1>Get In Touch</h1>
      <p>
        At the moment I am not looking for a new
        opportunity, but my inbox is always open.
        Whether it's a simple question or just
        want to say hello you can write to me and
        I will try to answer as soon as possible.
      </p>
      <a
        href={`mailto: moraru.bogdann26@gmail.com`}>
        <button> Say Hello </button>
      </a>
    </div>
  );
}

export default Contact;
