import React from "react";
import "./Projects.css";
import ProjectItem from "./ProjectItem";

function Projects() {
  return (
    <div className="projects">
      <div className="projects__title">
        <h1>Other personal projects</h1>
      </div>
      <div className="projects__content">
        <ProjectItem
          title="Xina's Blog Project"
          description="Web application for reading Xina's
            article. Custom blog. The website is
            structured by categories. Each
            category contains specific articles."
          tech1="React.js"
          tech2="CSS"
          tech3="Firebase"
          src="https://xinas-blog.web.app"
        />
        <ProjectItem
          title="Netflix Clone"
          description="Build a Netflix Clone. Include TMBP API, Navigation bar, Banner, Movies Categories, Movies Trailers."
          tech1="React.js"
          tech2="CSS"
          tech3="Firebase"
          src="https://netflix-clone-45975.web.app"
        />
        <ProjectItem
          title="FoodTrip Project"
          description="Still working on this, not posted yet. Mobile Application for helping the users choose the suitable restaurant."
          tech1="React Native"
          tech2="MySQL"
          tech3="Node.js"
          tech4="Express"
        />
      </div>
    </div>
  );
}

export default Projects;
