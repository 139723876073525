import React from 'react'
import './ProjectItem.css'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

function ProjectItem ({ title, description, tech1, tech2, tech3, tech4, src}) {
  return (
    <div className='projectItem'>
      <div className='projectItem__icons'>
        <FolderOpenIcon />
        <div className='projectItem__open'>
          <a href={src}>Open <OpenInNewIcon /></a>
        </div>
      </div>
      <div className='projectItem__title'>
        <h2>{title}</h2>
      </div>
      <div className='projectItem__description'>
        <p>
          {description}
        </p>
      </div>
      <div className='projectItem__technologies'>
        <h5>{tech1}</h5>
        <h5>{tech2}</h5>
        <h5>{tech3}</h5>
        <h5>{tech4}</h5>
      </div>
    </div>
  )
}

export default ProjectItem
