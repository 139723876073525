import React, { useState } from "react";
import "./Experience.css";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

function Experience() {
  const [activeIndex, setActiveIndex] = useState(
    0,
  );
  const [jobTitle, setJobTitle] = useState(
    experience.Luxoft.title,
  );
  const [jobLocation, setJobLocation] = useState(
    experience.Luxoft.location,
  );
  const [jobPeriod, setJobPeriod] = useState(
    experience.Luxoft.period,
  );
  const [
    jobDetails_1,
    setJobDetails_1,
  ] = useState(experience.Luxoft.details_1);
  const [
    jobDetails_2,
    setJobDetails_2,
  ] = useState(experience.Luxoft.details_2);
  const [
    jobDetails_3,
    setJobDetails_3,
  ] = useState(experience.Luxoft.details_3);
  const [
    jobDetails_4,
    setJobDetails_4,
  ] = useState("");

  return (
    <div className="experience" id="experience">
      <div className="experience__title">
        <h3>02.</h3>
        <h1>Experience</h1>
        <hr />
      </div>
      <div className="experience__content">
        <div className="experience__left">
          <button
            style={
              activeIndex === 0
                ? {
                    color: "#2997ff",
                    borderLeft:
                      "2px solid #2997ff",
                  }
                : null
            }
            onClick={() => {
              setActiveIndex(0);
              setJobTitle(
                experience.Luxoft.title,
              );
              setJobLocation(
                experience.Luxoft.location,
              );
              setJobPeriod(
                experience.Luxoft.period,
              );
              setJobDetails_1(
                experience.Luxoft.details_1,
              );
              setJobDetails_2(
                experience.Luxoft.details_2,
              );
              setJobDetails_3(
                experience.Luxoft.details_3,
              );
              setJobDetails_4(
                experience.Luxoft.details_4,
              );
            }}>
            Luxoft
          </button>
          <button
            style={
              activeIndex === 1
                ? {
                    color: "#2997ff",
                    borderLeft:
                      "2px solid #2997ff",
                  }
                : null
            }
            onClick={() => {
              setActiveIndex(1);
              setJobTitle(experience.Bcr.title);
              setJobLocation(
                experience.Bcr.location,
              );
              setJobPeriod(experience.Bcr.period);
              setJobDetails_1(
                experience.Bcr.details_1,
              );
              setJobDetails_2(
                experience.Bcr.details_2,
              );
              setJobDetails_3(
                experience.Bcr.details_3,
              );
              setJobDetails_4(
                experience.Bcr.details_4,
              );
            }}>
            BCR
          </button>
          <button
            style={
              activeIndex === 2
                ? {
                    color: "#2997ff",
                    borderLeft:
                      "2px solid #2997ff",
                  }
                : null
            }
            onClick={() => {
              setActiveIndex(2);
              setJobTitle(
                experience.Master.title,
              );
              setJobLocation(
                experience.Master.location,
              );
              setJobPeriod(
                experience.Master.period,
              );
              setJobDetails_1(
                experience.Master.details_1,
              );
              setJobDetails_2(
                experience.Master.details_2,
              );
              setJobDetails_3(
                experience.Master.details_3,
              );
              setJobDetails_4(
                experience.Master.details_4,
              );
            }}>
            M.S. UPB
          </button>
          <button
            style={
              activeIndex === 3
                ? {
                    color: "#2997ff",
                    borderLeft:
                      "2px solid #2997ff",
                  }
                : null
            }
            onClick={() => {
              setActiveIndex(3);
              setJobTitle(
                experience.Faculty.title,
              );
              setJobLocation(
                experience.Faculty.location,
              );
              setJobPeriod(
                experience.Faculty.period,
              );
              setJobDetails_1(
                experience.Faculty.details_1,
              );
              setJobDetails_2(
                experience.Faculty.details_2,
              );
              setJobDetails_3(
                experience.Faculty.details_3,
              );
              setJobDetails_4(
                experience.Faculty.details_4,
              );
            }}>
            B.S. UPB
          </button>
        </div>
        <div className="experience__right">
          <h3>
            {jobTitle}
            <span>{jobLocation}</span>
          </h3>
          <h4>{jobPeriod}</h4>
          <div className="experience__paragraph">
            <div className="experience__paragraphItem">
              {jobDetails_1 ? (
                <ArrowRightIcon />
              ) : null}
              <p>{jobDetails_1}</p>
            </div>
            <div className="experience__paragraphItem">
              {jobDetails_2 ? (
                <ArrowRightIcon />
              ) : null}
              <p>{jobDetails_2}</p>
            </div>
            <div className="experience__paragraphItem">
              {jobDetails_3 ? (
                <ArrowRightIcon />
              ) : null}
              <p>{jobDetails_3}</p>
            </div>
            <div className="experience__paragraphItem">
              {jobDetails_4 ? (
                <ArrowRightIcon />
              ) : null}
              <p>{jobDetails_4}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const experience = {
  Luxoft: {
    title: "Regular Software Developer ",
    location: "@Luxoft Professional Romania",
    period: "February 2019 - Present",
    details_1:
      "Develop applications for lithography machines that are the key to produce faster, cheaper and more energy-efficient microchips.",
    details_2:
      "Work with C++ language and Qt Framework.",
    details_3:
      "Write modern, performant, and robust code for internal projects. Communicate and collaborate with teams of engineers.",
    details_4: "",
  },
  Bcr: {
    title: "Intern ",
    location: "@Banca Comerciala Romana (BCR)",
    period: "July 2017 - October 2017",
    details_1:
      "Completed several projects that allowed me to sharpen my SQL and PL/SQL skills.",
    details_2:
      "Applied my technical knowledge to banking concepts and become familiar with data modeling and Oracle Database Architecture.",
    details_3:
      "Tested and maintained banking applications.",
    details_4:
      "Consistently beat deadlines and delivered against KPI’s.",
  },
  Master: {
    title:
      "Master of Science in Computer Science ",
    location:
      "@Faculty of Automatic Control And Computer Science, POLITEHNICA UNIVERSITY OF BUCHAREST",
    period: "October 2018 - July 2020",
    details_1: "Advanced Software Services",
    details_2: "",
    details_3: "",
    details_4: "",
  },
  Faculty: {
    title:
      "Bachelor of Science in Computer Science ",
    location:
      "@Faculty of Automatic Control And Computer Science, POLITEHNICA UNIVERSITY OF BUCHAREST",
    period: "October 2018 - July 2020",
    details_1:
      "Object-Oriented Programming | Java",
    details_2:
      "Web Programming | HTML / CSS / PHP / JS",
    details_3: "Data Structures | C",
    details_4: "Computer Graphics | C++",
  },
};

export default Experience;
