import React from "react";
import "./Work.css";
import PaintballProjectImage from "./assets/PaintballProject.png";
import MobatopiaProjectImage from "./assets/MobatopiaProject.png";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

function Work() {
  return (
    <div className="work" id="work">
      <div className="work__title">
        <h3>03.</h3>
        <h1>Some Things that I have built</h1>
        <hr />
      </div>
      <div className="work__contents">
        <div className="work__leftInversed">
          <h4>Featured Project</h4>
          <h2>Online Store Project</h2>
          <p>
            Web application for ordering board
            games online. Custom interface.
            Include features as adding, deleting,
            searching for products, calculating
            costs based on promotions and storing
            invoice data in the database.
          </p>
          <div className="work__technologies">
            <h5>React.js</h5>
            <h5>Javascript</h5>
            <h5>PHP</h5>
            <h5>MySQL</h5>
          </div>
          <div className="work__open">
            <a href={"https://mobatopia.ro"}>
              Open <OpenInNewIcon />
            </a>
          </div>
        </div>
        <div className="work__rightInversed">
          <a href="https://mobatopia.ro">
            <img
              src={MobatopiaProjectImage}
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="work__contents">
        <div className="work__left">
          <a href="http://paintballmioveni.ro/">
            <img
              src={PaintballProjectImage}
              alt=""
            />
          </a>
        </div>
        <div className="work__right">
          <h4>Featured Project</h4>
          <h2>Paintball Project</h2>
          <p>
            Web application for visualising data.
            Welcome Page for Timmy's Paintball.
            This website has a special design,
            include a short description of what
            the company offers, game packages,
            carousel with images, foto and video
            during the game.
          </p>
          <div className="work__technologies">
            <h5>React.js</h5>
            <h5>CSS</h5>
          </div>
          <div className="work__open">
            <a
              href={
                "https://paintballmioveni.ro/"
              }>
              Open <OpenInNewIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
