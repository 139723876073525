import React from "react";
import "./NavigationBar.css";
import logo from "./assets/logo_3.jpeg";
import ResumePdf from "./assets/Resume.pdf";

function NavigationBar() {
  return (
    <div className="navigationBar">
      <a href="/">
        <img
          className="navigationBar__logo"
          src={logo}
          alt=""
        />
      </a>
      <div className="navigationBar__left">
        <a href="/#about">
          <div className="navigationBar__item">
            <h5>01.</h5>
            <h4>About</h4>{" "}
          </div>
        </a>
        <a href="/#experience">
          <div className="navigationBar__item">
            <h5>02.</h5>
            <h4>Experience</h4>{" "}
          </div>
        </a>
        <a href="/#work">
          <div className="navigationBar__item">
            <h5>03.</h5>
            <h4>Work</h4>{" "}
          </div>
        </a>
        <a href="/#contact">
          <div className="navigationBar__item">
            <h5>04.</h5>
            <h4>Contact</h4>{" "}
          </div>
        </a>
        <a href={ResumePdf}>
          <button className="navigationBar__resumeBtn">
            {" "}
            Resume{" "}
          </button>
        </a>
      </div>
    </div>
  );
}

export default NavigationBar;
