import React from "react";
import "./About.css";
import aboutImage from "./assets/aboutme.jpeg";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

function About() {
  return (
    <div className="about" id="about">
      <div className="about__title">
        <h3>01.</h3>
        <h1>About Me</h1>
        <hr />
      </div>
      <div className="about__content">
        <div className="about__text">
          <h3>
            Hello! I'm Bogdan, a software engineer
            based in Bucharest.
          </h3>
          <h3>
            I never taking no for an answer and
            consistently working toward achieving
            my goals. Always trying to push my
            limits. I believe that time should not
            be wasted, so most of my free time is
            spent learning new skills or
            developing applications.
          </h3>
          <h3>
            After I gratuated from{" "}
            <a href="https://upb.ro/en/faculties/the-faculty-of-automatic-control-and-computer-science/">
              UPB Bucharest, Computer Science
            </a>
            , I joined the development team at{" "}
            <a href="https://www.luxoft.com/">
              Luxoft
            </a>{" "}
            where I work every day building faster
            and easy-to-use applications for our
            clients. Work hard in silence, let the
            success make the noise.
          </h3>
          <h3>
            Here are a few technologies I've been
            working with recently:
          </h3>
          <div className="about__technology">
            <div className="about__column1">
              <div className="about__technologyItem">
                <ArrowRightIcon />
                <h3>C/C++</h3>
              </div>
              <div className="about__technologyItem">
                <ArrowRightIcon />
                <h3>React</h3>
              </div>
              <div className="about__technologyItem">
                <ArrowRightIcon />
                <h3>HTML & CSS</h3>
              </div>
            </div>
            <div className="about__column2">
              <div className="about__technologyItem">
                <ArrowRightIcon />
                <h3>Qt Framework</h3>
              </div>
              <div className="about__technologyItem">
                <ArrowRightIcon />
                <h3>Javascript</h3>
              </div>
              <div className="about__technologyItem">
                <ArrowRightIcon />
                <h3>Node.js</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="about__image">
          <img src={aboutImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default About;
