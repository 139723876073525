import React from 'react'
import './Home.css'

function Home () {
  return (
    <div className='home'>
      <h4>Hi reader,</h4>
      <h1>I am Bogdan Moraru.</h1>
      <h1>I like to build things from scratch.</h1>
      <div className='home__text'>
        <h3>I'm a software engineer based in Bucharest, highly adaptable and enthusiastic individual looking to solve problems.</h3>
      </div>
      <a href={`mailto: moraru.bogdann26@gmail.com`}>
        <button className='home__getInTouchBtn'> GET IN TOUCH </button>
      </a>
    </div>
  )
}

export default Home
