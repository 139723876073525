import React from "react";
import "./Footer.css";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_icons">
        <a href="https://www.instagram.com/bogdannmoraru/">
          <InstagramIcon />
        </a>
        <a href="https://www.linkedin.com/in/bogdan-moraru/">
          <LinkedInIcon />
        </a>
      </div>
      <footer>Built by Bogdan Moraru</footer>
    </div>
  );
}

export default Footer;
