import React from 'react'
import './App.css'
import NavigationBar from './NavigationBar'
import About from './About'
import Home from './Home'
import Experience from './Experience'
import Work from './Work'
import Projects from './Projects'
import Contact from './Contact'
import Footer from './Footer'

function App () {
  return (
    <div className='app'>
      <NavigationBar />
      <Home />
      <About />
      <Experience />
      <Work />
      <Projects />
      <Contact />
      <Footer />
    </div>
  )
}

export default App
